<template>
	<div class="w-full flex">
		<Bunner :ids="id" level="ogv" type="1" class="mr-4"></Bunner>
		<Bunner :ids="id" level="ogv" type="2" class="mr-4"></Bunner>
		<Bunner :ids="id" level="ogv" type="3"></Bunner>
	</div>
</template>

<script>
// Глобальные данные стора
import { actions, getters, methods } from '@/store/store';
import Bunner from '@/components/Bunner.vue';

export default {
	components: { Bunner },
	computed: {
		...getters
	},
	methods: {
		...actions,
		...methods
	},
	data() {
		return {
			id: '0'
		};
	},
	mounted() {
		if (this.userIsInRoles(['Support', 'MZ_Employee', 'MZ_Observer'])) {
			this.id = this.$route.query.regionId;
		} else {
			this.id = this.getAuthUser.regionId;
		}
		if (this.id.length > 7) {
			this.getOGVbyId(this.id).then((res) => {
				this.id = res.data.oldId.toString();
			});
		}
	}
};
</script>
